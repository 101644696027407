import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import RSVP from "../components/rsvp"

// Images

import ImageBracu1 from "../images/bracu-1.jpg"
import ImageBracu2 from "../images/bracu-2.jpg"
import ImageBracu3 from "../images/bracu-3.jpg"

import ImageRachel from "../images/rachel.jpg"
import ImageChris from "../images/chris.jpg"
import ImageWilliam from "../images/william.jpg"
import ImageKim from "../images/kim.jpg"
import ImageMichael from "../images/michael.jpg"
import ImageKate from "../images/kate.jpg"
import ImageAndy from "../images/andy.jpg"
import ImageAmy from "../images/amy.jpg"
import ImageCarlene from "../images/carlene.jpg"
import ImageBecky from "../images/becky.jpg"

import ImageLula from "../images/lula.jpg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="px-6">
      <div className="container mx-auto mb-8 pt-20">
        <h1 className="title">Kiri &amp; Alex</h1>
        <div className="text-xl leading-none opacity-50">
          Bracu Olive Estate &mdash; Auckland
        </div>
      </div>

      <div className="section container">
        <div className="aspect aspect-3-2 lg:aspect-16-9">
          <figure className="fade">
            <img
              id="f1"
              className="h-full w-full object-cover"
              src={ImageBracu1}
              alt="Bracu"
            />
            <img
              id="f2"
              className="h-full w-full object-cover"
              src={ImageBracu2}
              alt="Bracu"
            />
            <img
              id="f3"
              className="h-full w-full object-cover"
              src={ImageBracu3}
              alt="Bracu"
            />
          </figure>
        </div>
      </div>

      <div id="wedding" className="section container">
        <div className="text-4xl lg:text-5xl leading-none mb-6">
          Tuesday 5th of January 2021
        </div>
        <div className="text-2xl mb-12">
          At 3pm with the reception to follow
        </div>
        <div className="text-3xl lg:text-4xl leading-tight mb-8">
          Bracu
          <br />
          49 Main Road <br />
          Bombay <br />
          Auckland
        </div>
        <a
          href="https://goo.gl/maps/Bb53xvdkzN7HWSGj6"
          target="_blank"
          className="button"
        >
          View on map
        </a>
      </div>

      <div id="party" className="section container">
        <h2 className="section-heading">Bridal Party</h2>
        <ol className="text-2xl leading-none flex flex-wrap -mb-6 -ml-6">
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageChris} alt="Chris Dearsley" />
            </figure>
            <h4 className="text-lg">Chris Dearsley</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageAmy} alt="Amy Johnson" />
            </figure>
            <h4 className="text-lg">Amy Johnson</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageKim} alt="Kim Boustridge" />
            </figure>
            <h4 className="text-lg">Kim Boustridge</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageCarlene} alt="Carlene Farnhill" />
            </figure>
            <h4 className="text-lg">Carlene Farnhill</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageWilliam} alt="William Dench" />
            </figure>
            <h4 className="text-lg">William Dench</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageKate} alt="Kate Herron" />
            </figure>
            <h4 className="text-lg">Kate Herron</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageMichael} alt="Michael King" />
            </figure>
            <h4 className="text-lg">Michael King</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-3">
              <img src={ImageBecky} alt="Becky Dower" />
            </figure>
            <h4 className="text-lg">Becky Dower</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-4">
              <img src={ImageAndy} alt="Andy Peat" />
            </figure>
            <h4 className="text-lg">Andy Peat</h4>
          </li>
          <li className="w-1/2 lg:w-1/5 pb-6 pl-6">
            <figure className="mb-4">
              <img src={ImageRachel} alt="Rachel Heselden-Bayes" />
            </figure>
            <h4 className="text-lg">Rachel Heselden-Bayes</h4>
          </li>
        </ol>
      </div>

      <div id="transport" className="section container">
        <div className="flex flex-wrap -ml-6">
          <div className="lg:w-2/3 pl-6">
            <h2 className="section-heading">Transport</h2>
            <div className="space-y-4">
              <p className="text-xl">
                A bus will be provided, leaving from and returning to Auckland
                CBD. Pick up/drop off location is to be confirmed. The bus will
                depart at 1:45pm sharp and will return from Bracu at midnight.
                You can choose if you need a seat in your RSVP below.
              </p>
              <p className="text-xl">
                If you wish to make your own way to Bracu please arrive by
                2.30pm. It is a 45 minute drive from Auckland CBD (
                <a
                  className="font-normal"
                  href="https://www.google.co.nz/maps/dir/Auckland+CBD,+Auckland/Bracu+Restaurant+49+Main+Road,+Bombay+2675/@-37.0200515,174.7208161,11z/data=!3m1!4b1!4m14!4m13!1m5!1m1!1s0x6d0d47fb48a99ab9:0x500ef6143a2b3e0!2m2!1d174.7633315!2d-36.8484597!1m5!1m1!1s0x6d72aa036bf9ee8f:0x1fce703feb2b3294!2m2!1d175.0191602!2d-37.1837323!3e0"
                  target="_blank"
                >
                  click here for directions
                </a>
                ). There is parking available at the venue and you can leave
                your car overnight.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="registry" className="section container">
        <div className="flex flex-wrap -ml-6">
          <div className="lg:w-2/3 pl-6">
            <h2 className="section-heading">Gift Registry</h2>
            <div className="space-y-4">
              <p className="text-xl">
                No gifts are required as your company is the most special gift
                to us. However, if you were thinking of giving a gift to help us on our
                way, we will have a wishing well at the reception.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div id="info" className="section container">
        <div className="flex flex-wrap -ml-6">
          <div className="lg:w-2/3 pl-6">
            <h2 className="section-heading">Other Information</h2>
            <ul className="space-y-12">
              <li>
                <h4 className="text-2xl leading-none mb-4">Accommodation</h4>
                <p className="text-xl">
                  If you wish to stay close to the venue, Bombay and Pukekohe
                  have accommodation options. Alternatively Auckland CBD has
                  much more to choose from and you can reserve a seat on the bus
                  to get you to the venue.
                </p>
              </li>
              <li>
                <h4 className="text-2xl leading-none mb-4">Children</h4>
                <p className="text-xl">
                  While we absolutely love your precious babies, we respectfully
                  ask this to be an adults only wedding with the exception of
                  immediate family and nursing babies. We hope that you will
                  understand this decision and are still able to join us on our
                  special day.
                </p>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div id="events" className="section container">
        <h2 className="section-heading">Other Events</h2>
        <div className="flex flex-wrap -ml-6">
          <div className="lg:w-1/2 pl-6">
            <figure className="aspect aspect-16-9 mb-6">
              <img
                className="h-full w-full object-cover"
                src={ImageLula}
                alt="Lula"
              />
            </figure>
            <div className="opacity-50 text-md mb-2">
              Wednesday 6th of January
            </div>
            <h4 className="text-2xl leading-none mb-6">
              Day After at The Lula Inn
            </h4>
            <p className="text-xl max-w-4xl mb-8">
              Please join us for a day after function from 2.30pm at The Lula
              Inn.
            </p>
            <a
              className="button"
              href="https://g.page/thelulainn?share"
              target="_blank"
            >
              View on map
            </a>
          </div>
        </div>
      </div>

      <div id="rsvp" className="section container">
        <RSVP />
      </div>
    </div>
  </Layout>
)

export default IndexPage
