import React from "react"

class RSVP extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedOption: "",
      selectedOptionTransport: "",
    }
    this.radioChange = this.radioChange.bind(this)
    this.radioChangeTransport = this.radioChangeTransport.bind(this)
    this.radioChangeDiet = this.radioChangeDiet.bind(this)
  }

  radioChange(e) {
    this.setState({
      selectedOption: e.currentTarget.value,
    })
  }

  radioChangeTransport(e) {
    this.setState({
      selectedOptionTransport: e.currentTarget.value,
    })
  }

  radioChangeDiet(e) {
    this.setState({
      selectedOptionDiet: e.currentTarget.value,
    })
  }

  render() {
    return (
      <div className="max-w-3xl">
        <h2 className="section-heading">Rsvp</h2>
        <div className="space-y-4 mb-8">
          <p className="text-xl">
            Please RSVP by 30th August 2020 and fill in one per invitee. We are
            only able to accommodate those listed on your invitation.
          </p>
        </div>
        <form
          className="flex flex-wrap"
          name="rsvp"
          method="post"
          data-netlify="true"
        >
          <input type="hidden" name="form-name" value="rsvp" />
          
          <input type="hidden" name="diet" />
          <input type="hidden" name="dietaryRequirements" />
          <input type="hidden" name="transport" />
          <input type="hidden" name="busThere" />
          <input type="hidden" name="busBack" />
          <input type="hidden" name="dayAfterFunction" />

          <div className="w-full mb-8">
            <label className="form-label">What's your name?</label>
            <input
              type="text"
              className="w-64 border border-gray-300 p-2 rounded-none"
              name="name"
              required
            />
          </div>
          <div className="w-full mb-8">
            <div className="form-label">Will you be coming?</div>
            <div className="mt-1">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-black h-5 w-5"
                  name="attending"
                  value="Yes"
                  checked={this.state.selectedOption === "Yes"}
                  onChange={this.radioChange}
                  required
                />
                <span className="ml-3 text-xl">Yes, I can attend</span>
              </label>
            </div>
            <div className="mt-1">
              <label className="inline-flex items-center">
                <input
                  type="radio"
                  className="form-radio text-black h-5 w-5"
                  name="attending"
                  value="No"
                  checked={this.state.selectedOption === "No"}
                  onChange={this.radioChange}
                  required
                />
                <span className="ml-3 text-xl">No, I cannot attend</span>
              </label>
            </div>
          </div>
          {this.state.selectedOption === "Yes" && (
            <div>
              <div className="w-full mb-8">
                <div className="form-label">
                  Do you have any dietary requirements?
                </div>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-black h-5 w-5"
                      name="dietaryRequirements"
                      value="Yes"
                      checked={this.state.selectedOptionDiet === "Yes"}
                      onChange={this.radioChangeDiet}
                      required
                    />
                    <span className="ml-3 text-xl">Yes</span>
                  </label>
                </div>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-black h-5 w-5"
                      name="dietaryRequirements"
                      value="No"
                      checked={this.state.selectedOptionDiet === "No"}
                      onChange={this.radioChangeDiet}
                      required
                    />
                    <span className="ml-3 text-xl">No</span>
                  </label>
                </div>
              </div>
              {this.state.selectedOptionDiet === "Yes" && (
                <div className="w-full mb-8">
                  <label className="form-label">
                    What are your requirements?
                  </label>
                  <input
                    type="text"
                    className="w-64 border border-gray-300 p-2 rounded-none"
                    name="diet"
                    required
                  />
                </div>
              )}
              <div className="w-full mb-8">
                <div className="form-label">
                  Will you need transport to or from the venue?
                </div>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-black h-5 w-5"
                      name="transport"
                      value="Yes"
                      checked={this.state.selectedOptionTransport === "Yes"}
                      onChange={this.radioChangeTransport}
                      required
                    />
                    <span className="ml-3 text-xl">Yes</span>
                  </label>
                </div>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-black h-5 w-5"
                      name="transport"
                      value="No"
                      checked={this.state.selectedOptionTransport === "No"}
                      onChange={this.radioChangeTransport}
                      required
                    />
                    <span className="ml-3 text-xl">No</span>
                  </label>
                </div>
              </div>
              {this.state.selectedOptionTransport === "Yes" && (
                <div className="w-full mb-8">
                  <div className="form-label">
                    Do you need transport there, back or both?
                  </div>
                  <div className="mt-1">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-black h-5 w-5"
                        name="busThere"
                        value="Yes"
                      />
                      <span className="ml-3 text-xl">I need the bus there</span>
                    </label>
                  </div>
                  <div className="mt-1">
                    <label className="inline-flex items-center">
                      <input
                        type="checkbox"
                        className="form-checkbox text-black h-5 w-5"
                        name="busBack"
                        value="Yes"
                      />
                      <span className="ml-3 text-xl">I need the bus back</span>
                    </label>
                  </div>
                </div>
              )}
              <div className="w-full mb-8">
                <div className="form-label">
                  Are you coming to the day after function?
                </div>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-black h-5 w-5"
                      name="dayAfterFunction"
                      value="Yes"
                      required
                    />
                    <span className="ml-3 text-xl">Yes</span>
                  </label>
                </div>
                <div className="mt-1">
                  <label className="inline-flex items-center">
                    <input
                      type="radio"
                      className="form-radio text-black h-5 w-5"
                      name="dayAfterFunction"
                      value="No"
                      required
                    />
                    <span className="ml-3 text-xl">No</span>
                  </label>
                </div>
              </div>
            </div>
          )}
          <div className="w-full mb-8">
            <button className="button">Send RSVP</button>
          </div>
        </form>
      </div>
    )
  }
}

export default RSVP
